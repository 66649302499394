import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";

import { ESeparationService } from "src/app/core/services/e-separation.service";

@Component({
  selector: 'app-ndc',
  templateUrl: './ndc.component.html',
  styleUrls: ['./ndc.component.css']
})
export class NdcComponent implements OnInit {
  user: any;
  lndUserType: any;
  name: any;
  showcard: Boolean;
  showhr: Boolean;
  emp: any;
  showPOA:boolean;
  id: any;
  teamData: any;
  showAmdoc:boolean;
  link:any;
  showNoRecordsAvailable: boolean = false;
  showesep: boolean;
  showndcBtn: boolean;

  constructor(
    private learnerService: LearnerService,
    private loginService: LoginService,
    private appraisalService: AppraisalService,
    public loaderService: LoaderService,
    private eSeparationService: ESeparationService,
  ) { }

  ngOnInit() {
     
       
    this.learnerService.getUserType().subscribe(res => {
        this.user = res['lnd_user_type'];
        this.lndUserType = true;
        localStorage.setItem('user_type', res['lnd_user_type']);
    },
        (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
                this.loginService.logout();
            }
        });
    this.learnerService.getEmployeeDetails().subscribe((res: any) => {

        this.emp = res.data;
        this.name = res.data.name;
        this.id = res.data.emp_id;
        console.log(this.emp, "----------emp data")
    },
        (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
                this.loginService.logout();
            }
        })
        this.eSeparationService.getNDCExitFormList().subscribe((res: any) => {
              console.log(res);
              //this.teamData=res.data;
              this.teamData=res.data.exit_forms;
              this.checkExitForms();            
            },
              (error: any) => {
                  console.log(error, error.error, error.message);
                  if (error.error == 'Signature has expired') {
                      this.loginService.logout();
                  }
              });
}



            checkExitForms() {
              // Check if exit_forms array exists and has no data
              if (!this.teamData || this.teamData.length === 0) {
                this.showNoRecordsAvailable = true;
              }
            }

          getStatusIcon(status: boolean | null): string {
            if (status === false) {
              return '../../../assets/images/schedule.svg';
            } else if (status === null) {
              return '../../../assets/images/lock.svg';
            } else if (status === true) {
              return '../../../assets/images/check_circle.svg';
            }
            return ''; // Default icon if needed
          }

          getStatusTooltip(status: boolean | null): string {
            if (status === false) {
              return 'Pending';
            } else if (status === null) {
              return 'Restricted (You do not have the necessary permissions to access it.)';
            } else if (status === true) {
              return 'Completed';
            }
            return ''; // Default tooltip if needed
          }


onViewClick(id: any) {
   this.link=`/ndc/${id}`
  window.open(this.link, '_self');
  
}


expensecardshow(showclaimdata) {
  this.showcard = showclaimdata;
}
hrmanagercardshow(showhrmanagerdata){
  this.showhr=showhrmanagerdata
  
}
poashow(showpoa){
  this.showPOA=showpoa;
}
amdocshow(showamdoc){
  this.showAmdoc=showamdoc;
}



showEseparation(showEseparationdata) {
  this.showesep = showEseparationdata;
}
showNDCBtn(showNDCData) {
  this.showndcBtn = showNDCData;
}

}