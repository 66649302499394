<span>
    <app-header-global></app-header-global>
</span>
<div class="main">
    <div class="content" >
        <form [formGroup]="professionalInfoForm" class="personalInfo">

            <p class="upheading">Professional Information</p>

            <div class="border border-black p-3">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Employee's Details</span>
                    </div>
                </div>

                <div class="row">

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="">Employment Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="employment_type" class="infofield" required>
                                    <option value="">Select Employment Type</option>
                                    <option *ngFor="let type of employmentTypes" [value]="type[0]">{{ type[1] }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('employment_type')?.errors?.['required'] && professionalInfoForm.get('employment_type')?.touched">
                                    Employment Type is required.
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">

                        <div class="row">
                            <div class="col-sm-12">
                                <label>Employee ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="emp_id" class="infofield" required
                                    placeholder="Employee ID">
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('emp_id')?.errors?.['required'] && professionalInfoForm.get('emp_id')?.touched">
                                    Employee ID is required.
                                </div>
                                <div class="erroMessage" 
                                    *ngIf="empIdErrorMessage">
                                    {{empIdErrorMessage}}
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label for="jobTitleSelect">Job Title</label>
                            </div>
                            
                            <div class="col-sm-12">
                                <select id="jobTitleSelect" class="infofield" formControlName="job_title">
                                    <option value="">Select Job Title</option>
                                    <option *ngFor="let title of jobTitles" [value]="title[0]">{{title[1]}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('job_title')?.errors?.['required'] && professionalInfoForm.get('job_title')?.touched">
                                    Job Title is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    

                    
                    



                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Functional Designation</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_designation" class="infofield"
                                    placeholder="Functional Designation">
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('functional_designation')?.errors?.['required'] && professionalInfoForm.get('functional_designation')?.touched">
                                    Functional Designation is required.
                                    </div>  -->
                            </div>
                        </div>
                    </div>


                </div>


                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">E-Mail</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="email" formControlName="email" class="infofield" required
                                    placeholder="E-Mail">
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['required'] && professionalInfoForm.get('email')?.touched">
                                    E-Mail is required.
                                </div>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('email')?.errors?.['amplussolarEmail'] && professionalInfoForm.get('email')?.touched">
                                    E-Mail must be in the format of &#64;amplussolar.com.
                                </div>
                                <div class="erroMessage" 
                                    *ngIf="emailErrorMessage">
                                    {{emailErrorMessage}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Joining</label>
                            </div>
                            <div class="col-sm-12">
                               
                                    <input id="doj" name="doj" required type="date" class="infofield"
                                        placeholder="Date of Joining" formControlName="doj" [min]="minDate"
                                        [max]="maxDate" />
                                        <div class="erroMessage"
                                        *ngIf="professionalInfoForm.get('doj')?.errors?.['required'] && professionalInfoForm.get('doj')?.touched">
                                        Date of Joining is required.
                                   
                                        </div>

                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Physical Location</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="physical_location" class="infofield" required>
                                    <option value="">Select Physical Location</option>
                                    <option *ngFor="let location of physicalLocations" [value]="location[0]">{{
                                        location[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('physical_location')?.errors?.['required'] && professionalInfoForm.get('physical_location')?.touched">
                                    Physical location is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Manager</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="transformedManagers" bindLabel="name" formControlName="manager"
                                        placeholder="Select Manager" [searchable]="true" [multiple]="false">
                                    </ng-select>
                                </div>
                              
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('manager')?.errors?.['required'] && professionalInfoForm.get('manager')?.touched">
                                    Manager is required.
                                </div>
                            </div>
                        </div>
                    </div>





                </div>
                <div class="row">

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Department</label>
                            </div>
                            <div class="col-sm-12">
                                <select id="desigSelect" class="infofield" formControlName="department">
                                    <option value="">Select Department</option>
                                    <option *ngFor="let dept of departments " [value]="dept[0]">{{dept[1]}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('department')?.errors?.['required'] && professionalInfoForm.get('department')?.touched">
                                    Department is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Aadhar -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Functional Department</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="functional_department" class="infofield"
                                    placeholder="Functional Department">

                                    <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('functional_department')?.errors?.['required'] && professionalInfoForm.get('functional_department')?.touched">
                                    Functional Department is required.
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Business</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="business" class="infofield" required>
                                    <option value="">Select Business</option>
                                    <option *ngFor="let business of businesses" [value]="business[0]">{{ business[1] }}
                                    </option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('business')?.errors?.['required'] && professionalInfoForm.get('business')?.touched">
                                    Business is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Company</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="company" class="infofield" required>
                                    <option value="">Select Company</option>
                                    <option *ngFor="let company of companies" [value]="company[0]">{{ company[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('company')?.errors?.['required'] && professionalInfoForm.get('company')?.touched">
                                    Company is required.
                                    </div>
                            </div>
                        </div>
                    </div>



                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Hiring Type</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="hiring_type" class="infofield" required>
                                    <option value="">Select Hiring Type</option>
                                    <option *ngFor="let type of hiringTypes" [value]="type[0]">{{ type[1] }}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('hiring_type')?.errors?.['required'] && professionalInfoForm.get('hiring_type')?.touched">
                                    Hiring Type is required.
                                    </div> 
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        

            <!-- Personal Information Section -->
            <p class="upheading">Personal Information</p>

            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Person's Details</span>
                    </div>
                </div>

                <div class="row">
                    <!-- Initials -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Initials</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="initials" class="infofield" required>
                                    <option value="">Select Initials</option>
                                    <option *ngFor="let initial of initials" [value]="initial">{{ initial }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- First Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>First Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="fname" class="infofield" placeholder="First Name"
                                    required>
                            </div>
                        </div>
                    </div>
                    <!-- Middle Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Middle Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="mname" class="infofield" placeholder="Middle Name">
                            </div>
                        </div>
                    </div>
                    <!-- Last Name -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Last Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="lname" class="infofield" placeholder="Last Name"
                                    required>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="row">
                    <!-- Date of Birth -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Date of Birth</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">
                                    <input name="dob" required type="date" class="infofield" placeholder="Date of Birth"
                                        formControlName="dob" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Gender -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Gender</label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="gender" class="infofield" required>
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- Marital Status -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Marital Status</label>
                            </div>
                            <div class="col-sm-12">
                                <input formControlName="marital_status" placeholder="Marital Status"
                                    name="marital_status" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Father Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input formControlName="father_name" placeholder="Father Name"
                                    name="father_name" class="infofield" required>
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('marital_status')?.errors?.['required'] && professionalInfoForm.get('marital_status')?.touched">
                                    Marital Status is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- Aadhar -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Aadhar Number</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="aadhar_no" class="infofield"
                                        placeholder="Aadhar Number" minlength="12" maxlength="12" required>


                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">PAN Number</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="pan_no" class="infofield"
                                        placeholder="PAN Number (ex: ABCDE0000L)" minlength="10" maxlength="10"
                                        required>


                                </div>

                            </div>
                        </div>
                    </div>


                    
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Spouse Name<span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="spouse_name" class="infofield"
                                        placeholder="Spouse Name" maxlength="100">

                                    <!-- <div class="erroMessage"
                                        *ngIf="professionalInfoForm.get('spouse_name')?.errors?.['pattern'] && professionalInfoForm.get('spouse_name')?.touched">
                                        Entern valid value
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Blood Group<span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="blood_group" class="infofield"
                                        placeholder="Blood Group" maxlength="4">

                                    <div class="erroMessage"
                                        *ngIf="professionalInfoForm.get('blood_group')?.errors?.['pattern'] && professionalInfoForm.get('blood_group')?.touched">
                                        Entern valid value
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Previous Organisation<span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <div class="d-flex input-wrapper">

                                    <input type="text" formControlName="prev_org" class="infofield"
                                        placeholder="Previous Organisation" maxlength="100">

                                    <div class="erroMessage"
                                        *ngIf="professionalInfoForm.get('prev_org')?.errors?.['pattern'] && professionalInfoForm.get('prev_org')?.touched">
                                        Entern valid value
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Address Details Section -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Address Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- City -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>City</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="city" name="city" placeholder="City"
                                    class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Postal Code -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Postal Code</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="postal_code" name="postal_code"
                                    placeholder="Postal Code" class="infofield" minlength="6" maxlength="6"
                                    pattern="\d*" required>

                            </div>
                        </div>
                    </div>
                    <!-- Address -->
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Correspondance Address</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="address" name="address" placeholder="Address"
                                    class="infofield" required>

                            </div>
                        </div>
                    </div>
                </div>
                 <br>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Permanent Address</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="permanent_address" name="address"
                                    placeholder="Address" class="infofield" required>
                                <!-- <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('permanent_address')?.errors?.['required'] && professionalInfoForm.get('permanent_address')?.touched">
                                    Address is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Contact Details Section -->
            </div>
            <div class="border border-black p-3 mt-2">

                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Contact Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- Personal E-Mail Id -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Personal E-Mail Id</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="email" formControlName="personal_email" name="personal_email"
                                    placeholder="Personal E-Mail Id" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Personal Contact No. -->
                    <div class="col-sm-3">
                        <div class="col-sm-12">
                            <label>Personal Contact No.</label>
                        </div>
                        <div class="col-sm-12">
                            <input type="text" formControlName="mobile_no" name="mobile_no"
                                placeholder="Personal Contact No." class="infofield" minlength="10" maxlength="10"
                                pattern="\d*" required>


                        </div>
                    </div>
                    <!-- Alternate Contact No. -->
                    <div class="col-sm-3">
                        <div class="col-sm-12">
                            <label>Alternate Contact No.</label>
                        </div>
                        <div class="col-sm-12">
                            <input type="text" formControlName="alternate_mobile_no" name="alternate_mobile_no"
                                placeholder="Alternate Contact No." class="infofield" minlength="10" maxlength="10"
                                pattern="\d*">

                        </div>
                    </div>
                </div>
            </div>


            <!-- Qualification Details Section -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Qualification Details</span>
                    </div>
                </div>
                <div class="row">
                    <!-- Qualification (up to UG) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (up to UG)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_ug" name="qualification_ug"
                                    placeholder="Qualification (up to UG)" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Qualification (PG & above) -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Qualification (PG & above)</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="qualification_pg" name="qualification_pg"
                                    placeholder="Qualification (PG & above)" class="infofield">

                            </div>
                        </div>
                    </div>
                    <!-- Professional Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Professional Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="total_experience" name="total_experience"
                                    placeholder="Professional Experience" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <!-- Solar Experience -->
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Solar Experience</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="solar_experience" name="solar_experience"
                                    placeholder="Solar Experience" class="infofield">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- bank details -->
            <div class="border border-black p-3 mt-2">
                <div class="row subheading">
                    <div class="col-sm-3">
                        <span>Bank Details</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Account Holder Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="account_holder_name" name="account_number"
                                    placeholder="Account Nmumber" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Account Number</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="account_number" name="account_number"
                                    placeholder="Account Nmumber" class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Bank Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="bank_name" name="bank_name" placeholder="Bank Name"
                                    class="infofield" required>

                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>IFSC Code</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="ifsc_code" name="ifsc_code" placeholder="IFSC Code"
                                    class="infofield" required>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-3">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">UAN Number <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="uan_number" name="uan_number"
                                    placeholder="UAN Number" class="infofield" required>
                                <div class="erroMessage"
                                    *ngIf="professionalInfoForm.get('uan_number')?.errors?.['required'] && professionalInfoForm.get('uan_number')?.touched">
                                    UAN is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-right">
                <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                    fields</button> -->
                <button mat-button type="submit" *ngIf="gotData" class="universalbtn"
                    [disabled]="professionalInfoForm.invalid" (click)="onSubmit()">Submit</button>
            </div>
        </form>

    </div>

</div>


   
