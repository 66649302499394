<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)"
    (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"(notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)"></app-header-global>

<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA"
            [showamdoc]="showAmdoc"   [showEsep]="showesep" [showNDCBtn]="showndcBtn"></app-side-overlay-menu>
    </div>

    <div class="col-sm-10">
        <div class="row empList">
            <div class="col-10">
                <h4>No Dues certificate (NDC)</h4>
            </div>
            <div class="col-2" style="text-align: right;">
                <!-- <a class="ndcBtn">NDC Section</a> -->
            </div>
        </div>

        <table class="employeeTable">
            <tr>
                <th>Employee Code</th>
                <th>Name</th>
                <th>Designation</th>
                <th>Manager</th>
                <th>Department</th>

                <th>Admin</th>
                <th>IT</th>
                <th>Finance</th>
                <th>HR</th>
                <th>Action</th>
            </tr>
            <tr *ngFor="let item of teamData">
                <td>{{ item.emp_code }}</td>
                <td>{{ item.employee_name }}</td>
                <td>{{ item.designation }}</td>
                <td>{{ item.manager_name }}</td>
              
                

                <td>
                    <img 
                      class="imgIcon" 
                      [src]="getStatusIcon(item.status.Department)" 
                      [matTooltip]="getStatusTooltip(item.status.Department)" />
                    
                  </td>
                  
                  <td>
                    <img 
                      class="imgIcon" 
                      [src]="getStatusIcon(item.status.Admin)" 
                      [matTooltip]="getStatusTooltip(item.status.Admin)" />
                    
                  </td>
                  
                  <td>
                    <img 
                      class="imgIcon" 
                      [src]="getStatusIcon(item.status.IT)" 
                      [matTooltip]="getStatusTooltip(item.status.IT)" />
                    
                  </td>
                  
                  <td>
                    <img 
                      class="imgIcon" 
                      [src]="getStatusIcon(item.status.Finance)" 
                      [matTooltip]="getStatusTooltip(item.status.Finance)" />
                    
                  </td>
                  
                  <td>
                    <img 
                      class="imgIcon" 
                      [src]="getStatusIcon(item.status.HR)" 
                      [matTooltip]="getStatusTooltip(item.status.HR)" />
                    
                  </td>
                  
                <td class="viewLink" (click)="onViewClick(item.exit_form_id)">
                    View 
                    <img class="imgIconArrow" src="../../../assets/images/arrow_outward.svg" />
                </td>
            
            </tr>




              

        </table>


        <div class="noData" *ngIf="showNoRecordsAvailable">
            <img src="../../../assets/images/Empty-cuate 1.svg" />
            <div class="noRecords">
                <p class="heading">No Records Available</p>
                <p class="subHeading">It looks like there's nothing here yet.</p>
            </div>
        </div> 


    </div>