import { Component, OnInit } from "@angular/core";
import { LoginService } from "src/app/core/login/login.service";
import { AppraisalService } from "src/app/core/services/appraisal.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { HttpClient } from '@angular/common/http';
import { ESeparationService } from "src/app/core/services/e-separation.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-ndc-employee-details',
  templateUrl: './ndc-employee-details.component.html',
  styleUrls: ['./ndc-employee-details.component.css']
})
export class NdcEmployeeDetailsComponent implements OnInit {
  user: any;
  lndUserType: any;
  name: any;
  showcard: Boolean;
  showhr: Boolean;
  emp: any;
  showPOA:boolean;
  id: any;
  teamData: any;
  showAmdoc:boolean;
  questions: any;
  answers: any[] = [];
  isLoading = true;
  empData:any;
  emppro:any;
  exitFormId:number;
  item = { amount: null };
  maxAmount = 100000; // Maximum allowed amount
  isAmountInvalid = false;
  showesep: boolean;
  showndcBtn: boolean;

  data: any = {}; // Replace with your JSON object
  sections: string[] = [];



  constructor(
    private learnerService: LearnerService,
    private loginService: LoginService,
    private appraisalService: AppraisalService,
    public loaderService: LoaderService,
    private eSeparationService: ESeparationService,
    private http: HttpClient,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {



    

    




     const id = this.route.snapshot.params["id"];
     this.exitFormId =id;
    this.learnerService.getUserType().subscribe(res => {
        this.user = res['lnd_user_type'];
        this.lndUserType = true;
        localStorage.setItem('user_type', res['lnd_user_type']);
    },
        (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
                this.loginService.logout();
            }
        });
    this.learnerService.getEmployeeDetails().subscribe((res: any) => {

        this.emp = res.data;
        this.name = res.data.name;
        this.id = res.data.emp_id;
        console.log(this.emp, "----------emp data")
    },
        (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
                this.loginService.logout();
            }
        })
        this.eSeparationService.getTeamSeperation().subscribe((res: any) => {
              console.log(res);
             this.teamData=res.data;
          },
              (error: any) => {
                  console.log(error, error.error, error.message);
                  if (error.error == 'Signature has expired') {
                      this.loginService.logout();
                  }
              });

              this.eSeparationService.getNDCEmployeeDetails(id).subscribe((res: any) => {
                console.log(res);
                this.data = res.data;
                this.emppro = res.data.profile;

                this.sections = Object.keys(this.data.questions);
                for (const section of this.sections) {
                  this.data.questions[section].sort((a: any, b: any) => a.order - b.order);
                }
            
                // Extract section names (keys) dynamically
                if (this.data.questions) {
                  this.sections = Object.keys(this.data.questions);
                }
            
                
               this.isLoading = false;
            },
                (error: any) => {
                    console.log(error, error.error, error.message);
                    if (error.error == 'Signature has expired') {
                        this.loginService.logout();
                    }
                });
            }




            onSelectChange(item: any) {
              if (item.received === 'NA' || item.received === '') {
                item.amount = null; // Clear amount
                item.remarks = '';  // Clear remarks
              }
            }

            handleSubmit(section: string): void {
              // Prepare the data for submission
              const responses = this.data.questions[section].map((item: any) => ({
                question_id: item.id,
                received: item.received,
                amount: item.amount || null,
                remarks: item.remarks || '',
              }));
          
              const payload = {
                exit_form_id: this.exitFormId,
                responses: responses,
              };
          
              console.log('Payload to be submitted:', payload);

              
              this.eSeparationService.postSubmitAnswers(payload).subscribe({
                next: (response: any) => {
                  console.log('Response:', response);
              
                  if (response && response.status === true) {
                      window.location.reload();
                  } else {
                    alert(response.message);
                  }
                },
                error: (error) => console.error('Error:', error)
              });

              
          
            }
      

            onKeyDown(event: KeyboardEvent, item: any) {
              const inputValue = (event.target as HTMLInputElement).value;

              // Prevent typing a leading zero if the value is empty or already starts with 0
              if (inputValue === '' && event.key === '0') {
                event.preventDefault();
              }

              // Optional: Ensure no additional leading zeros are added if value starts with a zero
              if (inputValue.startsWith('0') && event.key !== 'Backspace') {
                event.preventDefault();
              }
            }

            

           
          
           showEseparation(showEseparationdata) {
              this.showesep = showEseparationdata;
            }
           showNDCBtn(showNDCData) {
              this.showndcBtn = showNDCData;
            }




expensecardshow(showclaimdata) {
  this.showcard = showclaimdata;
}
hrmanagercardshow(showhrmanagerdata){
  this.showhr=showhrmanagerdata
  
}
poashow(showpoa){
  this.showPOA=showpoa;
}
amdocshow(showamdoc){
  this.showAmdoc=showamdoc;
}

}
