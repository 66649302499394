<div class="manage-proposal-wrapper" style="width: 700px; max-height: fit-content;">
<div class="row">
    <div class="col title">
        {{data.popup_title}} for Employee : {{data.name}} ({{data.id}}) 
    </div>
    <div class="col-1" style="text-align: end; cursor: pointer; font-size: 3vh;">
      <i class="fa fa-close"  (click)="cancel()"></i>
    </div>
</div>
<div class="row" *ngFor="let item of selfData; let i =index">
    <div class="col" style=" border-bottom: 0.2vh solid #ccc; padding: 2vh;">
      <div class="row">
        <div class="col questions">
         {{item.title}}
        </div>
      </div>
      <div *ngIf="!item.ValArray" class="row">
        <div class="col ans">
          <p *ngFor="let line of item.value.split('\n')">
            <i *ngIf="line.trim()!=''" class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> {{line}}</p>
        </div>
      </div>
      <ng-container *ngIf="item.ValArray">
        <div *ngFor="let val of item.value; let j =index">
          <div class="row">
            <div class="col answers">
              <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 1vh;"></i> {{val.name}}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col">
        <button class="btn btn-outline-primary" (click)="cancel()" style="width: -webkit-fill-available;
width: -moz-available; margin: 1vh;">Go Back</button>
    </div>
  </div> -->
</div>