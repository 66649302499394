<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)" (notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)" 
></app-header-global>
<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu   [showEsep]="showesep" [showNDCBtn]="showndcBtn"
        [showClaim]="showcard" [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"></app-side-overlay-menu>

    </div>
    <div class="col-sm-10 card_div">
    <span *ngFor="let pdata of product,let i=index">
        <a id="link{{i}}" href="{{pdata.link}}" target="_blank"><div class="cards" >
            <div class="imgsec">
                <img src="{{pdata.imglink}}" id="img{{i}}" alt="" srcset="">
            </div>
            <div class="discsec">
                <div class="pname">
                   {{pdata.name}}
                </div>
                <div class="discription">
                   {{pdata.description}}
                </div>
                <div class="row">
                    <div class="col">
                       <!-- <p class="link"><u></u><u>View <i class="fa fa-arrow-up" style=" rotate: 45deg;"
                          aria-hidden="true"></i></u>
                       </p> -->
                       <p class="link"><u></u><u>View<img class="arrow-image" src="../../../assets/images/arrows.svg" alt="">
                       </u>
                       </p>
                    </div>
                 </div>
            </div>
            
        </div></a>
    </span>
    </div>
</div>
