import { Component, Inject } from '@angular/core';
import { OnInit } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EmponboardingService } from '../../employee-onboarding/services/emponboarding.service';
import { HrserviceService } from '../../hr-manager/service/hrservice.service';
import { BusinessAppraisalComponent } from '../../appraisal/business-appraisal/business-appraisal.component';
import { ActionPopupComponent } from '../../action/action.component';
import { ToastrService } from 'ngx-toastr';
// import { EmponboardingService } from '../../../services/emponboarding.service';

function amplussolarEmailValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (email && !email.endsWith('@amplussolar.com')) {
      // If the email does not end with @amplussolar.com
      return { amplussolarEmail: true };
    }
    return null; // If the email is valid or the control is empty
  };
}

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  fb = new FormBuilder();
  departments: any;
  companies: any;
  managers: any;
  statuses: any;
  employmentTypes: any;
  hiringTypes: any;
  physicalLocations: any;
  businesses: any;
  umail: any;
  empId: any;
  email: any;
  personalInfoForm: FormGroup;
  professionalInfoForm: FormGroup;
  initials: any;;
  dialogRef: any;
  gotData: boolean = false;
  minDate: any;
  maxDate: any;
  on_roll_emp_id: any;
  off_roll_emp_id: any;
  jobTitles: any;
  exitReasons: any;
  genders: any;
  ic_tc_cl: any;
  transformedManagers: any;
  redirect_emails: any;
  redirect_employee: any;
  user_exists: boolean = true;
  notice = [{
    "key": "Yes",
    "value": true
  },
  {
    "key": "No",
    "value": false
  }];
  redirect_req = [{
    "key": "Yes",
    "value": true
  },
  {
    "key": "No",
    "value": false
  }]




  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private empOnboardingService: EmponboardingService,
    private hrService: HrserviceService,
    private toast: ToastrService,
  ) {

    this.personalInfoForm = this.fb.group({})
    this.professionalInfoForm = this.fb.group({
      employment_type: ['', Validators.required],
      emp_id: ['', [Validators.required]],
      initials: [''],
      job_title: ['', Validators.required],
      functional_designation: [''],
      doj: [null, Validators.required],
      physical_location: ['', Validators.required],
      manager: [null, Validators.required],
      department: [null, Validators.required], //get from employee table
      functional_department: [''],
      business: [null, Validators.required],
      company: [null, Validators.required],
      hiring_type: ['', Validators.required],
      status: [null, Validators.required],
      email: ["", [Validators.required, amplussolarEmailValidator()]],
      fname: ['', Validators.required],
      mname: [''],
      lname: [''],
      dob: [null, Validators.required],
      gender: ['', Validators.required],
      mobile_no: ['', Validators.required],
      total_experience: [''],
      solar_experience: [''],
      qualification_ug: [''],
      qualification_pg: [''],
      last_promotion_date: [null],
      resignation_date: [null],
      notice_period: [null],
      relieving_date: [null],
      exit_date: [null],
      exit_reason: [null],
      exit_remarks: [''],
      ic_tl_cl: [0],
      redirect_employee: [null],
      redirect_required: [false],
      sap_id: [''],
      paternity_maternity_leave: [0]
    })


  }


  ngOnInit() {
    this.professionalInfoForm.get('employment_type')?.valueChanges.subscribe((value) => {
      if (value === 'On-Roll') {
        this.professionalInfoForm.get('emp_id')?.setValue(this.on_roll_emp_id);
      } else {
        this.professionalInfoForm.get('emp_id')?.setValue(this.off_roll_emp_id);
      }
    });
    this.umail = this.route.snapshot.queryParamMap.get('umail');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.empId = this.route.snapshot.queryParamMap.get('emp_id');

    this.hrService.getCompanies().subscribe((res: any) => {
      this.companies = res.data.companies;
      console.log(this.companies)
    })

    this.hrService.getDepartments().subscribe((res: any) => {
      this.businesses = res.data;
    })

    this.empOnboardingService.getEmpDefaultParams(this.email).subscribe((response: any) => {
      this.jobTitles = response.jobTitles;
      // this.departments = response.depts;
      // this.companies = response.companies;
      this.managers = response.managers;
      this.transformedManagers = this.managers.map((manager: any[]) => ({
        id: manager[0],
        name: manager[1]
      }));

      // this.businesses = response.businesses;
      let year = parseInt(response.date.slice(0, 4));
      let month = (response.date.slice(5, 7))
      let day = (response.date.slice(8, 10))
      this.minDate = `${year}-${month}-${day}`
      this.on_roll_emp_id = response.on_roll_emp_id;
      this.off_roll_emp_id = response.off_roll_emp_id;
      this.maxDate = `${year + 1}-${month}-${day}`
      this.statuses = response.statuses;
      this.employmentTypes = response.employmentTypes;
      this.hiringTypes = response.hiringTypes;
      this.physicalLocations = response.physicalLocations;
      this.genders = response.genders;
      this.initials = response.initials;
      this.exitReasons = response.exit_reason;
      this.ic_tc_cl = response.ic_tc_cl;
      this.user_exists = response.user_exists;

      this.getEmployeeInfo(this.empId);
      //   this.empOnboardingService.getProfile(this.umail, this.email).subscribe((response: any) => {
      //     // create controls in persolInfoForm for each key in response
      //     Object.keys(response).forEach((key) => {
      //       this.personalInfoForm.addControl(key, this.fb.control(null));
      //     });

      //     // this.personalInfoForm.get('emp_id')?.setValue();
      //     this.personalInfoForm.patchValue(response);
      //     this.personalInfoForm.disable();
      //     this.professionalInfoForm.get('emp_id')?.disable();
      //     this.gotData = true;
      //      (error: any) => {
      //       console.log(error);
      //     }
      //   })


    })

  }

  getEmployeeInfo(id) {
    this.hrService.employeeInfo({ emp_id: id, email: this.email }).subscribe((res: any) => {
      this.getDepartments(res.data.business);
      this.redirect_emails = res.data.redirect_emails;
      let exit_reason;
      if (res.data.exit_reason == "nan") {
        exit_reason = null;
      }
      else {
        exit_reason = res.data.exit_reason;
      }
      let manager;
      for (var i = 0; i < this.transformedManagers.length; i++) {
        if (res.data.manager == this.transformedManagers[i].id) {
          manager = this.transformedManagers[i].name;
        }
      }
      let company;
      for (var i = 0; i < this.companies.length; i++) {
        if (res.data.company == this.companies[i].company_id) {
          company = this.companies[i].name;
        }
      }
      this.professionalInfoForm.patchValue({
        employment_type: res.data.employment_type,
        emp_id: id,
        initials: res.data.initials,
        job_title: res.data.job_title,
        functional_designation: res.data.functional_designation,
        doj: res.data.doj,
        physical_location: res.data.physical_location,
        manager: manager,
        department: res.data.department, //get from employee table
        functional_department: res.data.functional_department,
        business: res.data.business,
        company: company,
        hiring_type: res.data.hiring_type,
        status: res.data.status,
        email: res.data.email,
        fname: res.data.fname,
        mname: res.data.mname,
        lname: res.data.lname,
        dob: res.data.dob,
        gender: res.data.gender,
        mobile_no: res.data.mobile_no,
        total_experience: res.data.total_experience,
        solar_experience: res.data.solar_experience,
        qualification_ug: res.data.qualification_ug,
        qualification_pg: res.data.qualification_pg,
        last_promotion_date: res.data.last_promotion_date,
        resignation_date: res.data.resignation_date,
        notice_period: res.data.notice_period,
        relieving_date: res.data.relieving_date,
        exit_date: res.data.exit_date,
        exit_reason: exit_reason,
        exit_remarks: res.data.exit_remarks,
        ic_tl_cl: res.data.ic_tl_cl,
        redirect_employee: res.data.redirect_employee,
        redirect_required: res.data.redirect_required,
        sap_id: res.data.sap_id,
        paternity_maternity_leave: res.data.paternity_maternity_leave
      });
      console.log(res, "---------------------- employee info");
    })
  }

  getDepartments(id?) {
    this.professionalInfoForm.value.department = null;
    this.departments = [];
    console.log(id);
    console.log(this.professionalInfoForm.value.business);
    console.log("in get departments");
    let businessId;
    if (this.professionalInfoForm.value.business) {
      businessId = this.professionalInfoForm.value.business;
    }
    else {
      businessId = id;
    }
    for (var i = 0; i < this.businesses.length; i++) {
      if (this.businesses[i].id == businessId) {
        this.departments = this.businesses[i].departments;
        console.log(this.departments, '----departments')
      }
    }
    console.log(this.departments, '----departments')
  }

  checkSolarExperience() {

    let formVal = this.professionalInfoForm.value.solar_experience;

    const res: string = formVal;
    console.log(res);
    const str: string[] = res.split(" ");
    console.log(str);

    if (res == '' || res == null) {
      return true;
    }
    else if (str.length > 0 && str.length <= 2) {
      if (Number(str[0]) < 0 && Number(str[0]) > 60) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
      else if (Number(str[2]) % 1 != 0) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
      else if (str[1] == 'Years' || str[1] == 'years' || str[1] == 'Year' || str[1] == 'year') {
        // this.toast.error("Please add experience in 'YY Years MM Months' format");
        return true;
      }
      else {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
    }

    else if (str.length == 3) {
      this.toast.error("Please add experience in 'YY Years MM Months' format");
      return false;
    }

    else if (str.length > 2 && str.length < 5) {

      if (Number(str[2]) > 0 && Number(str[2]) <= 12) {

        if (Number(str[2]) % 1 != 0) {
          this.toast.error("Please add experience in 'YY Years MM Months' format");
          return false;
        }
        else if (str[3] == 'Months' || str[3] == 'months' || str[3] == 'Month' || str[3] == 'month') {

          return true;
        }
        else {
          this.toast.error("Please add experience in 'YY Years MM Months' format");
          return false;
        }
      }
      else if (Number(str[2]) < 0) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
      else if (Number(str[2]) > 12) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }


    }
    else {
      this.toast.error("Please add experience in 'YY Years MM Months' format");
      return false;
    }

  }
  checkTotalExperience() {

    let formVal = this.professionalInfoForm.value.total_experience;

    const res: string = formVal;
    console.log(res);
    const str: string[] = res.split(" ");
    console.log(str);

    if (res == '' || res == null) {
      return true;
    }
    else if (str.length > 0 && str.length <= 2) {
      if (Number(str[0]) < 0 && Number(str[0]) > 60) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
      else if (Number(str[2]) % 1 != 0) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
      else if (str[1] == 'Years' || str[1] == 'years' || str[1] == 'Year' || str[1] == 'year') {
        // this.toast.error("Please add experience in 'YY Years MM Months' format");
        return true;
      }
      else {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
    }

    else if (str.length == 3) {
      this.toast.error("Please add experience in 'YY Years MM Months' format");
      return false;
    }

    else if (str.length > 2 && str.length < 5) {

      if (Number(str[2]) > 0 && Number(str[2]) <= 12) {

        if (Number(str[2]) % 1 != 0) {
          this.toast.error("Please add experience in 'YY Years MM Months' format");
          return false;
        }
        else if (str[3] == 'Months' || str[3] == 'months' || str[3] == 'Month' || str[3] == 'month') {

          return true;
        }
        else {
          this.toast.error("Please add experience in 'YY Years MM Months' format");
          return false;
        }
      }
      else if (Number(str[2]) < 0) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }
      else if (Number(str[2]) > 12) {
        this.toast.error("Please add experience in 'YY Years MM Months' format");
        return false;
      }


    }
    else {
      this.toast.error("Please add experience in 'YY Years MM Months' format");
      return false;
    }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  isValid() {
    let formvalue = this.professionalInfoForm.value;
    if (formvalue.mobile_no == '' || formvalue.mobile_no == null) {
      this.toast.error("Please enter Contact");
      return false;
    }
    if (formvalue.job_title == '' || formvalue.job_title == null) {
      this.toast.error("Please enter Job Title");
      return false;
    }
    if (formvalue.doj == '' || formvalue.doj == null) {
      this.toast.error("Please enter Date of Joining");
      return false;
    }
    if (formvalue.physical_location == '' || formvalue.physical_location == null) {
      this.toast.error("Please enter Physical Location");
      return false;
    }
    // if(formvalue.functional_designation =='' || formvalue.functional_designation == null){
    //   this.toast.error("Please enter Functional Designation");
    //   return false;
    // }
    // if(formvalue.functional_department =='' || formvalue.functional_department == null){
    //   this.toast.error("Please enter Functional Department");
    //   return false;
    // }
    if (formvalue.hiring_type == '' || formvalue.hiring_type == null) {
      this.toast.error("Please enter Hiring Type");
      return false;
    }
    // if(formvalue.lname =='' || formvalue.lname == null){
    //   this.toast.error("Please enter Last Name");
    //   return false;
    // }
    if (formvalue.qualification_ug == '' || formvalue.qualification_ug == null) {
      this.toast.error("Please enter UG Qualification");
      return false;
    }
    if (formvalue.department == '' || formvalue.department == null) {
      this.toast.error("Please enter Department");
      return false;
    }
    if ((formvalue.redirect_required && formvalue.redirect_employee == '') || formvalue.redirect_required && formvalue.redirect_employee == null) {
      this.toast.error("Please select Redirect Employee");
      return false;
    }
    if ((formvalue.status == 1 && formvalue.exit_date == '') || (formvalue.status == 1 && formvalue.exit_date == null)) {
      this.toast.error("Please enter Exit Date");
      return false;
    }
    if ((formvalue.status == 1 && formvalue.exit_reason == '') || (formvalue.status == 1 && formvalue.exit_reason == null)) {
      this.toast.error("Please enter Exit Reason");
      return false;
    }
    if ((formvalue.status == 1 && formvalue.exit_remarks == '') || (formvalue.status == 1 && formvalue.exit_remarks == null)) {
      this.toast.error("Please enter Exit Remarks");
      return false;
    }
    else {
      return true;
    }
  }
  onSubmit() {
    // this.professionalInfoForm.get('emp_id')?.enable();
    console.log(this.professionalInfoForm.value)
    this.professionalInfoForm.value.redirect_required = JSON.parse(this.professionalInfoForm.value.redirect_required);
    this.professionalInfoForm.value.notice_period = JSON.parse(this.professionalInfoForm.value.notice_period);
    this.professionalInfoForm.value.business = Number(this.professionalInfoForm.value.business);
    this.professionalInfoForm.value.department = Number(this.professionalInfoForm.value.department);
    this.professionalInfoForm.value.status = Number(this.professionalInfoForm.value.status);
    if (this.isValid() && this.checkSolarExperience() && this.checkTotalExperience()) {
      let manager;
      manager = this.professionalInfoForm.value.manager;
      this.professionalInfoForm.value.manager = manager.id;
      let company;
      company = this.professionalInfoForm.value.company;
      this.professionalInfoForm.value.company = company.company_id;
      let params = {
        action: 'submit',
      }
      let dialogRef = this.dialog.open(ActionPopupComponent, {
        width: "300px",
        data: {
          buttontext: "Submit",
          desc: "Do you want to Submit?",
        },
      });
      const sub = dialogRef.componentInstance.option.subscribe((data) => {
        if (data) {
          sub.unsubscribe();
          if (data == "success") {
            this.hrService.addEmployeeInfo({ emp_id: this.empId, email: this.email }, this.professionalInfoForm.value)
              .subscribe((response: any) => {
                window.location.reload();
                console.log(response);
              },
                (error: any) => {
                  console.log(error);
                  this.toast.error(error.error.message + "!!! Please check form and try again!")
                });
          }
        }
      });
    }

  }
}

