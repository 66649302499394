import { Component, Input, OnInit,Output } from '@angular/core';
import { AllLinksComponent } from 'src/app/core/all-links/all-links.component';
import { LearnerService } from 'src/app/core/services/learner.service';
import { EmployeeDocumentsComponent } from '../employee-documents/employee-documents.component';
import { EventEmitter } from '@angular/core';
import { TemplateServiceService } from 'src/app/components/template-module/service/template-service.service'
import { Router, ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-side-overlay-menu',
  templateUrl: './side-overlay-menu.component.html',
  styleUrls: ['./side-overlay-menu.component.css']
})

export class SideOverlayMenuComponent implements OnInit {
  documents: any = [];
  desired_title = "Employee Handbook 2023";
  file_url: string;
  isSubmenuVisible: boolean = false;

  documentRes:any
  @Output()
  notify:EventEmitter<any>=new EventEmitter<any>();
  

  @Input()
  showClaim:boolean;
  @Input()
  showHr:boolean;
  type:any='document'

  @Input()
  showpoa:boolean;
  @Input()
  showEsep:boolean;
  @Input()
  showNDCBtn:boolean;

  @Input()
  showamdoc:boolean;
templateNames: { id: string, name: string }[] = [];

  constructor(
    private learnerService: LearnerService,
    public allLink : AllLinksComponent,
    private templateservice: TemplateServiceService,
    private router: Router
  ) { 
    
  }
 
 ngOnInit(): void {
  this.templateservice.getTemplate(null).subscribe((response: any) => {
    let res=response.data
    // res.forEach()

    res.forEach(template => {
      console.log('the template name is ',template.name)
      this.templateNames.push({ id: template.id, name: template.name });

    });
  });
  
  this.learnerService.getDocumentsList(this.type).subscribe(res => {
    //console.log(res);
    this.documents = res;
    this.documentRes=this.documents;
    this.notify.emit(this.documentRes);
    const matching_object = this.documents.find(item => item.title === this.desired_title);

    // Retrieve the "file" value if a matching object is found
    if (matching_object) {
      this.file_url = matching_object.file;
    } else {
      console.log("No match found for the given title.");
    }
  },
    (error: any) => {
      console.log(error, error.error, error.message);
    });
  }

handBookPdf() {
  this.learnerService.getViewDownloadPdf(this.file_url).subscribe(res => {
    this.documents = res;
    window.open(this.documents.presigned_url, "_blank");
    window.location.reload();
  },
    (error: any) => {
      console.log(error, error.error, error.message);
    });
}
toggleSubmenu() {
  this.isSubmenuVisible = !this.isSubmenuVisible;
}
logTemplateName(template: { id: string, name: string }) {
  this.router.navigate(['/template/view_template'], {
    queryParams: { 
      service_id: template.id,
      filter:'total'

    }
  }).then(() => {
    // Reload the page after navigation
    window.location.reload();
  });
}
}