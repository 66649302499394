import { Component, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule, formatDate } from '@angular/common';

import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component';

import { QuestionService } from '../../dynamic-form/question.service';
import { QuestionBase } from '../../dynamic-form/question-base';
import { Observable } from 'rxjs';
import { AppraisalService } from 'src/app/core/services/appraisal.service';
import { ToastrService } from 'ngx-toastr';
import { CheckboxQuestion } from '../../dynamic-form/checkbox';
import { RadioQuestion } from '../../dynamic-form/radio';
import { TextboxQuestion } from '../../dynamic-form/textbox';
import { LoginService } from 'src/app/core/login/login.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  standalone: true,
  selector: 'self-appraisal',
  templateUrl: './self-appraisal.component.html',
  styleUrls: ['./self-appraisal.component.scss'],
  providers: [QuestionService],
  imports: [AsyncPipe, DynamicFormComponent, CommonModule],
})
export class SelfAppraisalComponent implements OnInit {
  questions$: Observable<QuestionBase<any>[]>;
  selfStatus: boolean = false;
  isValuearray: boolean = false;
  selfData: any;
  questions: any;
  errorMessage: any;
  errorFlag = false;
  dueDate: any;
  today: any;
  cycle: any;
  constructor(private service: QuestionService,
    private toast: ToastrService,
    private loaderService: LoaderService,
    private appraisalService: AppraisalService,
    private loginService: LoginService) {

  }
  ngOnInit(): void {
    this.today = formatDate(new Date(), "yyyy-MM-dd", 'en-IN');
    this.appraisalService.getSelfApprasail().subscribe((res: any) => {
      console.log(res);
      this.errorFlag = false;
      setTimeout(() => {
        this.loaderService.setLoading(false);
      }, 100);
      this.selfData = res.data.self_appraisal.response;
      localStorage.setItem('cycle', res.data.appraisal_cycle.name);
      this.dueDate = res.data.appraisal_cycle.due_date_for_ic;
      if (JSON.stringify(res.data) != '{}') {
        this.filterData(this.selfData);
        this.selfStatus = true;
      }
      else {
        this.selfStatus = false;
      }
    },
      (err: any) => {
        console.log(err);
        setTimeout(() => {
          this.loaderService.setLoading(false);
        }, 100);
        this.errorFlag = false;
        this.selfStatus = false;
        this.questions = [];
        this.appraisalService.getSelfAppraisalTemplate().subscribe((res: any) => {
          let filterData = res.data.self_appraisal_form;
          localStorage.setItem('cycle', res.data.appraisal_cycle.name);
          this.dueDate = res.data.appraisal_cycle.due_date_for_ic;
          for (let i = 0; i < filterData.length; i++) {
            let arrmeta = [];
            let arrvalid = [];
            arrvalid.push(filterData[i].validations);
            filterData[i]['validation'] = arrvalid;
            if (filterData[i].meta.json != null) {
              Object.keys(filterData[i].meta.json).forEach(function (key) {
                console.log('Key : ' + key + ', Value : ' + filterData[i].meta.json[key])
                let obj = {};
                obj['id'] = key;
                obj['name'] = filterData[i].meta.json[key];
                arrmeta.push(obj);
              })
              filterData[i].meta.json = arrmeta;
              filterData[i]['newMeta'] = arrmeta;
            }
          }
          // this.filteredData = filterData;
          // this.filterData();
          let ques = filterData;
          console.log(ques, "=-=-=-=-=-=-=-=-=-=-=-=-=-=-=")

          for (var i = 0; i < ques.length; i++) {
            if (ques[i].type == "textarea") {
              this.questions.push(
                new TextboxQuestion(ques[i])
              )
            }
            else if (ques[i].type == "checkbox") {
              this.questions.push(
                new CheckboxQuestion(ques[i])
              )
            }
            else if (ques[i].type == "radio") {
              this.questions.push(
                new RadioQuestion(ques[i])
              )
            }
          }
          console.log(this.questions.sort((a, b) => a.order - b.order), "[][][][][][][][][][][][][][][][][][][]");
          // return of(questions.sort((a, b) => a.order - b.order));
          this.questions = this.questions;
        },
          (error: any) => {
            console.log(error, error.error, error.message);
            if (error.error == 'Signature has expired') {
              this.loginService.logout();
            }
            else {
              this.errorFlag = true;
              this.errorMessage = error.error.errors;
            }
          })
      })

  }

  filterData(data) {
    let filterData = data;
    console.log(filterData)
    for (let i = 0; i < filterData.length; i++) {
      let arrmeta = [];
      console.log(typeof filterData[i].value != 'string', "typeof filterData[i].value != 'string'");
      if (typeof filterData[i].value != 'string') {
        console.log(filterData[i]);
        Object.keys(filterData[i].value).forEach(function (key) {
          console.log('Key : ' + key + ', Value : ' + filterData[i].value[key])
          let obj = {};
          obj['id'] = key;
          obj['name'] = filterData[i].value[key];
          arrmeta.push(obj);
        })
        this.isValuearray = true;
        filterData[i]['ValArray'] = this.isValuearray;
        filterData[i].value = arrmeta;
      }
      else {
        this.isValuearray = false;
        filterData[i]['ValArray'] = this.isValuearray;
      }
    }
    this.selfData = filterData;
    console.log(this.selfData, '============================')
    //   console.log(this.selfData, '============================')
  }

}

