import { Component, OnInit, ViewChild } from "@angular/core";
import { SlickCarouselComponent } from "ngx-slick-carousel";
import { ActivatedRoute } from "@angular/router";
import { MemoriesService } from "../service/memories.service";
MemoriesService;
@Component({
  selector: "app-memories-gallery",
  templateUrl: "./memories-gallery.component.html",
  styleUrls: ["./memories-gallery.component.css"],
})
export class MemoriesGalleryComponent implements OnInit {
  @ViewChild("slickMain") slickMain: SlickCarouselComponent | undefined;
  @ViewChild("slickThumbs") slickThumbs: SlickCarouselComponent | undefined;
  name: string;
  showcard: any;
  showhr: boolean;
  memories_id: number;
  slides: any[] = [];
  showesep: boolean;
  showndcBtn: boolean;

  constructor(
    private route: ActivatedRoute,
    private memories: MemoriesService
  ) {}

  ngOnInit(): void {
    this.memories_id = this.route.snapshot.params["id"];
    this.GetSlidesByMemoriesId(this.memories_id);
  }
  slideConfigMain = {
    slidesToShow:1,
    slidesToScroll:1,
    asNavFor: ".slick-thumbs",
    // autoplay: true,
    // autoplaySpeed: 5000,
    pauseOnHover: true,
    infinite: true,
    arrows:true,
    
  };

  slideConfigThumbs = {
    slidesToShow:6,
    slidesToScroll:1,
    asNavFor: ".slick-main",
    centerMode:true,
    infinite:true,
    focusOnSelect:true,
    arrows: false,
  };

  GetSlidesByMemoriesId(MemoriesId:number): void {
    this.memories.getSingleMemories(MemoriesId).subscribe((response: any) => {
      this.slides =response.files.map((file, index) => ({
        ...file,
        index,
      }));

      console.log(this.slides);
      if (this.slickMain && this.slickThumbs) {
        this.slickMain.slickGoTo(0);
        this.slickThumbs.slickGoTo(0);
      }
    });
  }

  goToSlide(index: number): void {
    if (this.slickMain) {
      this.slickMain.slickGoTo(index);
    }
  }

  expensecardshow(showclaimdata: boolean) {
    this.showcard = showclaimdata;
  }

  hrmanagercardshow(showhrmanagerdata: boolean) {
    this.showhr = showhrmanagerdata;
  }



 showEseparation(showEseparationdata) {
    this.showesep = showEseparationdata;
  }
 showNDCBtn(showNDCData) {
    this.showndcBtn = showNDCData;
  }
}
