<ng-container *ngIf="!isLoggedIn()">
   <div class="row">
       <div class="col" style="margin: 15vh 15vw 1vh; color: #777; font-size: 3vh; text-align: center;">
           Oops! It seems you are logged out. <br>
           Please Login again to continue :)
       </div>
   </div>
   <div class="row">
       <div class="col" style="margin: 1vh 15vw 15vh; text-align: center;">
           <button class="btn btn-primary" routerLink="/login" style="width: 30vw;">Go to Login</button>
       </div>
   </div>
</ng-container>
<span *ngIf="isLoggedIn()">
   <app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)" (notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)" 
   ></app-header-global>
 
</span>
<div class="row allcontent" *ngIf="isLoggedIn()">

   <div class="col-sm-2">
      <app-side-overlay-menu [showClaim]="showcard" [showHr]="showhr" (notify)="getDocument($event)" [showpoa]="showPOA" [showamdoc]="showAmdoc"   [showEsep]="showesep" [showNDCBtn]="showndcBtn"></app-side-overlay-menu>
   
   </div>
   <div class="col-sm-7">
      <div class="row">
         <div class="col-sm-12 bannerImg">
            <div id="myCarousel" class="carousel slide" data-bs-ride="carousel">
               <!-- Indicators -->
               <ol class="carousel-indicators">
                  <li data-bs-target="#myCarousel" data-bs-slide-to="0" class="active"></li>
                  <li data-bs-target="#myCarousel" data-bs-slide-to="1"></li>
                  <li data-bs-target="#myCarousel" data-bs-slide-to="2"></li>
                  <li data-bs-target="#myCarousel" data-bs-slide-to="3"></li>
               </ol>
               <!-- Wrapper for slides -->
               <div class="carousel-inner">
                  <div class="carousel-item active">
                      <figure>
                          <img src="../../../assets/images/ambanner1.svg" alt="" style="width:100%;">
                          <figcaption>Annual Offsite: United in Vision, Strengthened in Togetherness: Capturing our Collective Success!</figcaption>
                      </figure>
                  </div>
                  <div class="carousel-item">
                      <figure>
                          <img src="../../../assets/images/ambanner2.svg" alt="" style="width:100%;">
                          <figcaption>Spotlighting Excellence: Celebrating Achievement, Inspiring Success.</figcaption>
                      </figure>
                  </div>
                  <div class="carousel-item">
                      <figure>
                          <img src="../../../assets/images/ambanner3.svg" alt="" style="width:100%;">
                          <figcaption>Connecting Minds, Building Futures: Uniting Ideas, Empowering Success at Corporate Conferences.</figcaption>
                      </figure>
                  </div>
                  <div class="carousel-item">
                      <figure>
                          <img src="../../../assets/images/ambanner4.svg" alt="" style="width:100%;">
                          <figcaption>Fueling Success, Fostering Fun: Where Work and Play go hand in hand that's life at Amplus!</figcaption>
                      </figure>
                  </div>
              </div>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-sm-12">
            <div class=" d-flex newUpdate empdocs" (click)="employeeDocuments()">
               <!-- <span class="dotspan"><img src="../../../assets/images/dot.svg" alt=""></span> -->
               <span class="imgspan"><mat-icon svgIcon="announcementicon"></mat-icon></span>
               <p><span id="announcements" *ngIf="docRes && docRes.length > 0" >{{ docRes[0].title }},</span> added to the sections of Documents. All  employees are
                  requested to familiarize themselves with the update.
               </p>
               <p class="links"><u class="links">View
                  <span class="image-arrow"><mat-icon svgIcon="arrow"></mat-icon></span>
               </u>
               </p>
            </div>
         </div>
      </div>
      
      <div class="row">
         <ng-container *ngFor="let card of getAccessibleCards(); let i = index;">
             <div *ngIf="i % 2 === 0" class="w-100"></div>
             
             <div class="col gradient-box shadow" (click)="card.onClick()">
                 <div class="row">
                     <div class="col p30">
                         <p class="heading">{{ card.title }}</p>
                         <br>
                         <p class="content">{{ card.description }}</p>
                         <br>
                         <p class="italic">{{ card.accessibility }}</p>
                         <br>
                     </div>
                 </div>
                 <div class="row">
                     <div class="col">
                         <p class="link">
                             <u>View
                                 <span class="image-arrow"><mat-icon svgIcon="arrow"></mat-icon></span>
                             </u>
                         </p>
                     </div>
                 </div>
             </div>
             <div *ngIf="(i === getAccessibleCards().length - 1) && (i % 2 === 0)" class="col psudeogradiant-box"></div>
         </ng-container>
        
     </div>
     
      
      
   </div>
   <div class="col-sm-3">
      <div class="row linkofficial">
         <div class="col-sm-12">
            <a href="https://amplussolar.com/" target="_blank">
               <div class=" d-flex newUpdate Official">
                  <p>Visit Official Website</p>
                  <p class="link"><u>
                     <span class="image-arrow"><mat-icon svgIcon="arrow"></mat-icon></span>
                  </u>
                  </p>
               </div>
            </a>
         </div>
      </div>

      <div class="row">
         <div class="col-sm-12">
            <div class=" d-flex eventcalenderheading ">
             <span>Events Calendar</span>    
             </div>
            
         </div>
      </div>
       <div class="row eventcalender">
      <div class="col-sm-12">
         <app-event-calender [holidays]="holidaydates" [dates]="dates"></app-event-calender>
      </div>
       </div>
      <div class="row">


         <!-- <mat-form-field appearance="none">                
            <input matInput [matDatepicker]="picker"/>
            <mat-datepicker-toggle  matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon>
                    <img src="../../../assets/images/calendar.png"> Holiday List</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker [dateClass]="dateClass" #picker (opened)="streamOpened($event)"
                panelClass="endDate">

                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Close</button>
                
                  </mat-datepicker-actions>

            </mat-datepicker>
                
        </mat-form-field> -->
         
       
        
         <div class="col-sm-12">
            <div class="eheading event">
               Upcoming Events
            </div>
         </div>

      </div>

      <div class="row eventshow">
         <div class="col-sm-12">
            <div class="newUpdate">

               <ng-container *ngIf="event && event.length > 0; else noEvents">
                  <span *ngFor="let ev of event; let i=index">
                     <div class="events"> <a href="" data-bs-toggle="modal"
                           [attr.data-bs-target]="'#eventModal' + (i + 1)">
                           <p class="ename">{{ ev.name | titlecase}}</p>
                           <p class="edetails">{{ ev.date | date: 'MMM d' }}</p>
                        </a>
                     </div>


                     <!-- modal detail events -->
                     <div class="modal fade eventModal" id="eventModal{{i+1}}" tabindex="-1"
                        aria-labelledby="eventModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                           <div class="modal-content">
                              <div class="modal-body">
                                 <div class="head">
                                    <div class="psudeolist">{{ev.name}}</div>
                                    <div type="button" id="cross" class="btn-close" data-bs-dismiss="modal"
                                       aria-label="Close"></div>
                                 </div>
                                 <div class="eventdetails">
                                    {{ processDescription(ev.desc) }}
                                 </div>
                                 <div class="location">
                                    <div class="imglogo">
                                       <img src="../../../assets/images/locationicon.svg" alt="" srcset="">
                                    </div>
                                    <div class="locationname">
                                       <span >
                                          {{ev.location}}
                                       </span>
                                      
                                    </div>
                                 </div>
                                 <div class="joinlink" *ngIf="extractUrls(ev.desc).length > 0">
                                    <div class="linklogo">
                                       <img src="../../../assets/images/linkvector.svg" alt="" srcset="">
                                    </div> 
                                     <div class="linkurl" *ngFor="let url of extractUrls(ev.desc)">
                                       <a href="{{ url }}" target="_blank">{{ url }}</a> 
                                    
                                     <div class="copyimage" (click)="copyImage(url)">
                                       <img src="../../../assets/images/copyframe.svg" alt="" srcset="" >
                                     </div>
                                    </div>
                                 </div>
                                 <div class="otherdetails">
                                    <div class="datediv">
                                       <div class="datelogo">
                                          <img src="../../../assets/images/calenderimg.svg" alt="" srcset="">
                                       </div>
                                       <div class="eventdate"><span>{{ev.date |date}}</span></div>
                                    </div>
                                    <div class="timediv">
                                       <div class="timelogo">
                                          <img src="../../../assets/images/clockimg.svg" alt="" srcset="">
                                       </div>
                                       <div class="eventtime"><span>{{ev.start_time}}-{{ev.end_time}}</span></div>
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </span>
               </ng-container>

            </div>
         </div>
      </div>

      <ng-template #noEvents>
         <!-- Display an error image or message when there are no events -->
         <div class="error-message">
            <img src="../../../assets/images/errorimg.svg" alt="No events found">
            <span>No events for {{ getCurrentMonth() }} <br>
               Check out other months !</span>
         </div>
      </ng-template>


      <div class="row">
         <div class="col-sm-12">
            <div class="newUpdate upholiday">
               <div class="row">

                  <div class="col-sm-8" style="font-weight: 600;">
                     Upcoming Holidays
                  </div>
                  <div class="col-sm-4">
                     <a href="" data-bs-toggle="modal" data-bs-target="#holidayModal">View All</a>
                  </div>

               </div>
               <br>
               <div class="row holidayListDiv">
                  <div class="col-sm-12">
                     <span class="innerSpan1" *ngFor="let holiday of upcomingHolidays">{{holiday.name | titlecase}}
                        <span class="innerSpan2">{{holiday.date |date}}</span></span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<!-- modal for holiday list  -->
<div class="modal fade" id="holidayModal" tabindex="-1" aria-labelledby="holidayModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-xl">
      <div class="modal-content">
         <div class="modal-body">
            <div class="head">
               <div class="psudeolist"></div>
               <div type="button" id="cross" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></div>
            </div>
            <h6 class="title" id="holidyModalLabel">Holiday List</h6>
            <div class="box">
            <span *ngFor="let holiday of Holidays;let index=i">
               <div class="cardarea">
                  <div class="box">
                     <p>{{holiday.date | date:'MMM d'}}</p>
                  </div>
                  <div class="namediv">
                     <p class="hname">{{holiday.name}}</p>
                     <p>{{ holiday.date | date:'EEE' }}</p>
                  </div>
               </div>
            </span>
         
             <h6 class="title2" id="holidyModalLabel">Optional Holidays</h6>
            <span *ngFor=" let optionalholiday of optionalHolidays ;let index=j">
               <div class="cardarea">
                  <div class="box">
                     <p>{{optionalholiday.date | date:'MMM d'}}</p>
                  </div>
                  <div class="namediv">
                     <p>{{optionalholiday.name}}</p>
                     <p>{{ optionalholiday.date | date:'EEE' }}</p>
                  </div>
               </div>
            </span> 
         </div>
         </div>

      </div>
   </div>
</div>







<!-- <div *ngIf="lndUserType" class="row header-bg">
   <div class="col header">
       &nbsp; Amplus Empower
   </div>
   <div class="col-1 header"> (click)="logout()"
       <i class="fa fa-power-off" aria-hidden="true" style="cursor: pointer;"></i>
       <span style="font-size:30px;cursor:pointer" (click)="openNav()">&#9776; </span>
   </div>
   </div> -->
<!-- <app-side-overlay-menu (notify)="expensecardshow($event)"></app-side-overlay-menu> -->
<!-- <div class="wrapper">
   <div *ngIf="lndUserType" class="row prem">
       <div class="col user-header">
           Hello {{name}}! <img style="width:1.8%" src="../../../assets/images/Emoji.png">
           <span class="list">
               <mat-form-field appearance="none">                
                   <input matInput [matDatepicker]="picker"/>
                   <mat-datepicker-toggle  matSuffix [for]="picker">
                       <mat-icon matDatepickerToggleIcon>
                           <img src="../../../assets/images/calendar.png"> Holiday List</mat-icon>
                   </mat-datepicker-toggle>
                   <mat-datepicker [dateClass]="dateClass" #picker (opened)="streamOpened($event)"
                       panelClass="endDate">
   
                       <mat-datepicker-actions>
                           <button mat-button matDatepickerCancel>Close</button>
                       
                         </mat-datepicker-actions>
   
                   </mat-datepicker>
                       
               </mat-form-field>
           </span>
       </div>
   </div>
    -->