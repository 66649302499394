import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { LoginComponent } from "src/app/core/login/login.component";
import { LoginService } from "src/app/core/login/login.service";
import { LearnerService } from "src/app/core/services/learner.service";
import { LoaderService } from "src/app/core/services/loader.service";
declare var bootstrap;
@Component({
    selector: 'app-appraisal',
    templateUrl: './appraisal.component.html',
    styleUrls: ['./appraisal.component.scss']
})
export class AppraisalComponent implements OnInit {

    name: any;
    user: any;
    cycle: any = '2025-Jan'
    lndUserType: boolean;
    isAdmin: any;
    showcard: any;
    showhr: boolean;
    isSelf: any;
    isManager: any;
    isDepartment: any;
    isBusiness: any;
    isCompany: any;
    activeTab = 'self';
    showPOA:any;
    showAmdoc:any;
    
    showesep: boolean;
    showndcBtn: boolean;

    // @ViewChild('myTabRef') myTab: ElementRef;
    constructor(
        private learnerService: LearnerService,
        private loginService: LoginService,
        public loaderService: LoaderService
    ) { }

    ngOnInit() {
        $(document).ready(function(){
            $(this).scrollTop(0);
        });
        if (localStorage.getItem('tab')) {
            this.activeTab = localStorage.getItem('tab');
            console.log(localStorage.getItem('tab'), ",----------------------------------tab---")
        }
        console.log('in appraisal')
        this.learnerService.getUserType().subscribe(res => {
            this.user = res['lnd_user_type'];
            this.lndUserType = true;
            localStorage.setItem('user_type', res['lnd_user_type']);
        },
            (error: any) => {
                console.log(error, error.error, error.message);
                if (error.error == 'Signature has expired') {
                    this.loginService.logout();
                }
            });
        // this.user_type = localStorage.getItem('user_type');
        this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
        // this.user = localStorage.getItem('user_type');
        this.name = localStorage.getItem('name') == 'true' ? true : false;
        this.isSelf = localStorage.getItem('self') == 'true' ? true : false;
        this.isManager = localStorage.getItem('manager') == 'true' ? true : false;
        this.isDepartment = localStorage.getItem('department_head') == 'true' ? true : false;
        this.isBusiness = localStorage.getItem('business_head') == 'true' ? true : false;
        this.isCompany = localStorage.getItem('company') == 'true' ? true : false;
        this.cycle = localStorage.getItem('cycle');

        console.log(this.isSelf, "--------------------------paocshgdcbsjdkv--------------")
    }
    expensecardshow(showclaimdata) {
        this.showcard = showclaimdata;
    }

    hrmanagercardshow(showhrmanagerdata) {
        this.showhr = showhrmanagerdata

    }
    redirectToTab(tabIndex) {
        window.addEventListener("load", (event) => {
            alert(document.getElementById('self-tab'))
        });
        // setTimeout(() => {
        //     console.log(document.getElementById('self-tab'), "==========================")
        //     alert(document.getElementById('myTab'));
        // }, 3000)
        const tabLink = document.querySelectorAll('.nav-link')[tabIndex];
        if (tabLink) {
            (tabLink as HTMLAnchorElement).click();
        }
    }
    poashow(showpoa){
        this.showPOA=showpoa;
      }
    
    amdocshow(showamdoc) {
        this.showAmdoc = showamdoc;
      }

    
     showEseparation(showEseparationdata) {
        this.showesep = showEseparationdata;
      }
     showNDCBtn(showNDCData) {
        this.showndcBtn = showNDCData;
      }
}