<app-header-global (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"  (notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)"></app-header-global>

<div class="row allcontent">
    <div class="col-sm-2">
        <app-side-overlay-menu [showClaim]="showcard"  [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"    [showEsep]="showesep" [showNDCBtn]="showndcBtn"></app-side-overlay-menu>
    </div>

    <div class="col-sm-10 card_div">
          <p class="title">Memories</p>
        <div class="card_wrapper">
            <div class="row">
                <div class="col-sm-4" *ngFor="let data of memoriesdata" (click)="navigateToSubroute(data.routename)">
                      <div class="image_wrapper">
                        <div class="image_div">
                            <img [src]="data.thumbnail_image" alt="{{data.title}}" srcset=""> 
                         
                        </div>
                        <div class=" title_div">
                            {{data.title}}
                        </div>
                      </div>
                     
                 </div>
               
                </div>
        </div>
          
    </div>
</div>