import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MemoriesService } from "../service/memories.service";
@Component({
  selector: "app-memories-details",
  templateUrl: "./memories-details.component.html",
  styleUrls: ["./memories-details.component.css"],
})
export class MemoriesDetailsComponent implements OnInit {
  showcard: any;
  showhr: boolean;
  categories: string;
  subtitle: string;
  subroute: string;
  selectedCategory: any;
  selectedEvent: any;
  memoriesdata: any;
  showesep: boolean;
  showndcBtn: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private memories: MemoriesService
  ) {}
  ngOnInit(): void {
    this._getMemoriesCategories();
  }

  _getMemoriesCategories() {
    this.categories = this.route.snapshot.params["category"];
    this.memories.getMemoriesCategories(this.categories).subscribe((res) => {
      this.memoriesdata = res;
    });
  }

  navigateToSubroute(id:number) {
    this.router.navigate([`/memories/${this.categories}`,id]);
  }

  expensecardshow(showclaimdata) {
    this.showcard = showclaimdata;
  }

  hrmanagercardshow(showhrmanagerdata) {
    this.showhr = showhrmanagerdata;
  }

  showEseparation(showEseparationdata) {
    this.showesep = showEseparationdata;
  }
  showNDCBtn(showNDCData) {
    this.showndcBtn = showNDCData;
  }
}
