import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ESeparationService {
  getEmployeeDetailsURL = "separation/team_seperation/";
  getNDCList = "separation/ndc_exit_form_list/";
  getCanSubmitURL = "separation/can_submit/";
  submitExitFormURL = "separation/submit_exit_form/";
  getNDCEmployeeDetailsURL = "separation/ndc_question_answer/";
  baseUrl = environment.url;
  sessionUrl = "lnd/sessions/";
  employeeExitDetailsURL = "separation/exit_form_details/";
  acknowledgeURL = "separation/approval/";
  postSubmitAnswersURL = "separation/submit_ndc_answer/";
  postWithdrawURL = "separation/withdraw/";
  getgetuploadedDocumentURL = "separation/uploaded_document/?&id=";
  postExitDocumentsUploadURL = "separation/exit_documents_upload/";
  getSeparationEmpRequestsDetailsURL='appraisal/exit_portal_employee/?emp_id=';
  accessToken = localStorage.getItem("accessToken");
  getExitPortalPromotionURL='new_appraisal/exit_portal_promotion/?emp_id='
  constructor(private http: HttpClient) {}

  submitExitForm(data) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.post(this.baseUrl + this.submitExitFormURL, data, {
      headers,
    });
  }

  acknowledgeForm(data) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.post(this.baseUrl + this.acknowledgeURL, data, {
      headers,
    });
  }

  getTeamSeperation() {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getEmployeeDetailsURL, {
      headers,
    });
  }

  getNDCExitFormList() {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getNDCList, { headers });
  }

  getNDCEmployeeDetails(id) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getNDCEmployeeDetailsURL + id, {
      headers,
    });
  }

  getuploadedDocument(id) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getgetuploadedDocumentURL + id, {
      headers,
    });
  }

  getCanSubmit() {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getCanSubmitURL, { headers });
  }

  employeeExitDetails(id) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.employeeExitDetailsURL + id, {
      headers,
    });
  }


  getSeparationEmpRequestsDetails(id) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getSeparationEmpRequestsDetailsURL + id, {
      headers,
    });
  }
  getExitPortalPromotion(id) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.get(this.baseUrl + this.getExitPortalPromotionURL + id, {
      headers,
    });
  }

  postSubmitAnswers(data) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.post(this.baseUrl + this.postSubmitAnswersURL, data, {
      headers,
    });
  }
  postExitDocumentsUpload(data) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.post(
      this.baseUrl + this.postExitDocumentsUploadURL,
      data,
      { headers }
    );
  }

  postWithdraw(id) {
    const headers = new HttpHeaders().set("Authorization", this.accessToken);
    return this.http.post(this.baseUrl + this.postWithdrawURL + id, null, {
      headers,
    });
  }
}
