import { Component, OnInit } from '@angular/core';
import { AllLinksComponent } from 'src/app/core/all-links/all-links.component'
import { LearnerService } from 'src/app/core/services/learner.service';
import { LoginService } from 'src/app/core/login/login.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-employee-documents',
  templateUrl: './employee-documents.component.html',
  styleUrls: ['./employee-documents.component.css']
})
export class EmployeeDocumentsComponent implements OnInit {
  form: any;
  data: any;
  isAdmin: boolean = false;
  user: any;
  name: any;
  showhr:boolean;
  user_type: any;
  lndUserType: boolean = false;
  AllLinksComponent: any;
  documents: any = [];
  showcard:any;
  type:string='document';
  showPOA:any;
  showAmdoc:any;
  showesep: boolean;
  showndcBtn: boolean;

  constructor(
    public allLink: AllLinksComponent,
    private learnerService: LearnerService,
    private loginService: LoginService,
    private router: Router,
  ) { }
  ngOnInit(): void {
    this.learnerService.getUserType().subscribe(res => {
      this.user = res['lnd_user_type'];
      this.lndUserType = true;
      localStorage.setItem('user_type', res['lnd_user_type']);
    },
      (error: any) => {
        console.log(error, error.error, error.message);
        if (error.error == 'Signature has expired') {
          this.loginService.logout();
        }
      });
    // this.user_type = localStorage.getItem('user_type');
    this.isAdmin = localStorage.getItem('user_type') == 'admin' ? true : false;
    // this.user = localStorage.getItem('user_type');
    this.name = localStorage.getItem('name');


    this.learnerService.getDocumentsList(this.type).subscribe(res => {
      console.log(res);
      this.documents = res;
    },
      (error: any) => {
        console.log(error, error.error, error.message);
      });
  }

  viewDownloadPdf(file) {
    this.learnerService.getViewDownloadPdf(file).subscribe(res => {

      this.documents = res;
      // alert(this.documents.presigned_url);
      window.open(this.documents.presigned_url, "_blank");
      window.location.reload();
    },
      (error: any) => {
        console.log(error, error.error, error.message);

      });
  }
  home() {
    this.router.navigate(['home']);
  }

  expensecardshow(showclaimdata) {
  
    this.showcard = showclaimdata;
  }
  hrmanagercardshow(showhrmanagerdata){
    this.showhr=showhrmanagerdata
    
 }
  poashow(showpoa){
    this.showPOA=showpoa;
  }
  amdocshow(showamdoc){
    this.showAmdoc=showamdoc;
}
showEseparation(showEseparationdata) {

  this.showesep = showEseparationdata;
}
showNDCBtn(showNDCData) {
  this.showndcBtn = showNDCData;
}

}