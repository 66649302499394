<div *ngIf="!midCycle" class="manage-proposal-wrapper" style="width: 700px; max-height: fit-content;">
    <div class="row">
        <div class="col title">
            {{data.popup_title}} for Employee : {{data.name}} ({{data.id}})
        </div>
        <div class="col-1" style="text-align: end; cursor: pointer; font-size: 3vh;">
            <i class="fa fa-close" (click)="cancel()"></i>
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            What are person's accomplishments in last 12 month?
        </div>
    </div>
    <div class="row" style="border-bottom: 0.02vh solid #ccc;">
        <div class="col answers">
            <p *ngFor="let line of teamData.accomplishments.split('\n')">
                <i *ngIf="line.trim()!=''" class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 0vh 1vh;"></i> {{line}}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            According to you, what are the strengths of this employee?
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <p *ngFor="let line of teamData.strength.split('\n')">
                <i *ngIf="line.trim()!=''" class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 0vh 1vh;"></i> {{line}}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            According to you, what are the areas of improvement for this employee?
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <p *ngFor="let line of teamData.improvement.split('\n')">
                <i *ngIf="line.trim()!=''" class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 0vh 1vh;"></i> {{line}}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            CLIP Scores
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> Commitment: {{teamData.clip_commitment}}
        </div>
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> Leadership: {{teamData.clip_leadership}}
        </div>
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> Innovation: {{teamData.clip_innovation}}
        </div>
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> Performance: {{teamData.clip_performance}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            Considering this person's past quarter performance, I feel this person could be at a risk of low
            performance?
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.low_performance}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            Do you believe that this person is ready for promotion?
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.ready_for_promotion}}
        </div>
    </div>
    <div class="row" *ngIf="teamData.employment_type == 'Off-Roll'">
        <div class="col questions">
            Do you believe that this person is ready for on-roll?
        </div>
    </div>
    <div class="row" *ngIf="teamData.employment_type == 'Off-Roll'">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.offroll_to_onroll}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            Feedback for Employee
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <p *ngFor="let line of teamData.feedback.split('\n')">
                <i *ngIf="line.trim()!=''" class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 0vh 1vh;"></i> {{line}}
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            Have you had one-on-one discussion with the employee on their current performance?
        </div>
    </div>
    <div class="row">
        <div class="col answers">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.one_on_one_discussion}}
        </div>
    </div>
</div>

<div *ngIf="midCycle" class="manage-proposal-wrapper" style="width: 700px; max-height: fit-content;">
    <div class="row">
        <div class="col title">
            {{data.popup_title}} for Employee : {{data.name}} ({{data.id}})
        </div>
        <div class="col-1" style="text-align: end; cursor: pointer; font-size: 3vh;">
            <i class="fa fa-close" (click)="cancel()"></i>
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            1. Hypothetically, assuming the business prospects are not looking very good for the next 12 months and we
            are under pressure to reduce salary costs by half, even in such adverse conditions, I would like this person
            to be on my team :
        </div>
    </div>
    <div class="row" style="border-bottom: 0.02vh solid #ccc;">
        <div class="col answers">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                padding: 0vh 1vh;"></i> {{teamData.q1}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            2. If it were my money, I would award this person the highest possible compensation increase and bonus :
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> {{teamData.q2}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            3. I believe, in the last six months, this person stretched himself/ herself to seek new and better ways of
            doing things :
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> {{teamData.q3}}
        </div>
    </div>

    <div class="row">
        <div class="col questions">

            4. I find people in general love to work with this person and he/she also reaches out to collaborate with
            other team members :
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i> {{teamData.q4}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            5. I believe this person is ready for promotion considering his/her collaborative and mentoring skills :
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.ready_for_promotion}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            6. Considering this person's past six month's performance expected as per his/her role in the company, I
            feel this person could be at risk of low performance :
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.low_performance}}
        </div>
    </div>
    <div class="row">
        <div class="col questions">
            7. Would you like to recommend the salary correction / revision:
        </div>
    </div>
    <div class="row">
        <div class="col answers" style="border-bottom: 0.02vh solid #ccc;">
            <i class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
            padding: 0vh 1vh;"></i>{{teamData.increment}}%
        </div>
    </div>
    <div class="row" *ngIf="teamData.increment >0">
        <div class="col questions">
            8. If any correction recommended, please provide the basis for the same :
        </div>
    </div>
    <div class="row" *ngIf="teamData.increment >0">
        <div class="col answers">
            <div class="row" *ngIf="teamData.increment >0">
                <div class="col answers">
                    <p *ngFor="let line of teamData.increment_remarks.split('\n')">
                        <i *ngIf="line.trim()!=''" class="fa fa-circle" aria-hidden="true" style="color: #42478b !important; font-size: 1.3vh;
                            padding: 0vh 1vh;"></i> {{line}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>