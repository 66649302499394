<span>
  <app-header-global (notifyshowEseparation)="showEseparation($event)" (notifyshowNDC)="showNDCBtn($event)" 
  (notify)="expensecardshow($event)" (notifyhrmanager)="hrmanagercardshow($event)" (notifyPOA)="poashow($event)" (notifyAmdoc)="amdocshow($event)"></app-header-global>
</span>

  <div class="row allcontent">
    <div class="col-sm-2">
      <app-side-overlay-menu   [showEsep]="showesep" [showNDCBtn]="showndcBtn"
      [showClaim]="showcard"  [showHr]="showhr" [showpoa]="showPOA" [showamdoc]="showAmdoc"></app-side-overlay-menu>
    </div>
    <div class="col-sm-10  contentsection">
      <div class="main">
  
        <div class="content">
          <div class="heading">
            Employees List
          </div>
          <div class="table-responsive mt-3">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contact No</th>
                  <th>Personal Email-Id</th>
                  <th style="text-align: center;">Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr  *ngFor="let user of users$ | async"
                [ngClass]="{'disabled': user.status === 'user_pending'}" 
                (click)="user.status !== 'user_pending' && openUserProfile(user,user.status)">
                  <td>{{ user.fname }}</td>
                  <td>{{ user.mobile_no }}</td>
                  <td>{{ user.email }}</td>
                  <td><span [ngClass]="getStatusClass(user.status)">
                    {{ getStatusName(user.status) }}
                  </span></td>
                  <td><div class="row">
                    <div class="col">
                       <p class="link"><u></u><u>View<img class="arrow-image" src="../../../assets/images/arrows.svg" alt="">
                       </u>
                       </p>
                    </div>
                 </div></td>
                </tr>
              </tbody>
          
            </table>
          </div>
         
    
          <div class="row addrequest-btn  ">
            <div class="col-sm-9"></div>
             <div class="col-sm-3">
              <div class="btn-div">
                <button (click)="openDialog()"
                class="custom-btn approvebtn">
                Add New Request
              </button>
              </div>
             </div>
          </div>
        </div>
      </div>
    
    </div>
  </div>
  