import { Component, OnInit } from "@angular/core";
import { HrserviceService } from "../../hr-manager/service/hrservice.service";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { Notification, Subject } from "rxjs";
import { EmponboardingService } from "../../employee-onboarding/services/emponboarding.service";
import { LoginService } from "src/app/core/login/login.service";
import { LoaderService } from "src/app/core/services/loader.service";
import { MatDialog } from "@angular/material/dialog";
import { CompanyDialogComponent } from "../company-dialog/company-dialog.component";
@Component({
    selector: "app-company-list",
    templateUrl: "./company-list.component.html",
    styleUrls: ["./company-list.component.scss"],
})


export class CompanyListComponent implements OnInit {
    headingCompanies: string = "List of Companies";
    companylist: any;
    companyType: any;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private hrservice: HrserviceService,
        private EmpOnboardingService: EmponboardingService,
        private loginService: LoginService,
        private loaderService: LoaderService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.hrservice.getCompanies().subscribe((res: any) => {
            this.companylist = res.data.companies;
            this.companyType = res.data.company_type;

            for (var i = 0; i < this.companylist.length; i++) {
                for (var j = 0; j < this.companyType.length; j++) {
                    if (this.companylist[i].company_type == this.companyType[j].key) {
                        this.companylist[i].company_type = this.companyType[j].value;
                    }
                }
            }
        })
    }

    addCompany(){
        this.loaderService.setLoading(true);
        let dialogRef = this.dialog.open(CompanyDialogComponent, {
            data: {
                type: "create"
            },
        });
    }
    
    editCompany(company) {
        this.loaderService.setLoading(true);
        let type ;
        for(var i =0; i< this.companyType.length; i++){
            if(company.company_type == this.companyType[i].value){
              type = this.companyType[i].key;
            }
        }
        let dialogRef = this.dialog.open(CompanyDialogComponent, {
            data: {
                type: "edit",
                data: {
                    "company_id": company.company_id,
                    "name": company.name,
                    "code": company.code,
                    "address": company.address,
                    "company_type": type
                }
            },
        });
    }
}