<div *ngIf="data.type == 'edit'" class="manage-proposal-wrapper" style="width: 700px; max-height: fit-content;">
    <div class="main">
        <div class="content">
            <form [formGroup]="form" class="personalInfo">
                <p class="upheading">Edit Department Information</p>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Department ID</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="id" class="infofield" required
                                    placeholder="Department ID" disabled>
                                <div class="erroMessage"
                                    *ngIf="form.get('id')?.errors?.['required'] && form.get('id')?.touched">
                                    Department ID is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Department Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="name" class="infofield" required
                                    placeholder="Department Name" disabled>
                                <div class="erroMessage"
                                    *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                                    Department name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Head</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="empList" bindLabel="name" formControlName="head"
                                        placeholder="Select Head" [searchable]="true" [multiple]="false">
                                    </ng-select>
                                </div>
                                <div class="erroMessage"
                                    *ngIf="form.get('head')?.errors?.['required'] && form.get('head')?.touched">
                                    Head is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Abbreviation</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="abbr" class="infofield" placeholder="000">
                                <!-- <div class="erroMessage"
                                    *ngIf="form.get('code')?.errors?.['required'] && form.get('code')?.touched">
                                    Address is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Business <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="business" class="infofield" required>
                                    <option value="">Select Business</option>
                                    <option *ngFor="let obj of business " [value]="obj.id">{{obj.name}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="form.get('business')?.errors?.['required'] && form.get('business')?.touched">
                                    Business is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="text-right">
                <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                    fields</button> -->

                <button mat-button type="button" class="cancelbtn" (click)="cancel()">Cancel</button>
                <button mat-button type="submit" class="universalbtn" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="data.type == 'create'" class="manage-proposal-wrapper" style="width: 500px; max-height: fit-content;">
    <div class="main">
        <div class="content">
            <form [formGroup]="form" class="personalInfo">
                <p class="upheading">Add Department Information</p>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Department Name</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="name" class="infofield" required
                                    placeholder="Department Name">
                                <div class="erroMessage"
                                    *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                                    Department name is required.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Head</label>
                            </div>
                            <div class="col-sm-12">
                                <div class="search-container">
                                    <ng-select class="custom" [items]="empList" bindLabel="name" formControlName="head"
                                        placeholder="Select Head" [searchable]="true" [multiple]="false">
                                    </ng-select>
                                </div>
                                <div class="erroMessage"
                                    *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">
                                    Head is required.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Abbreviation</label>
                            </div>
                            <div class="col-sm-12">
                                <input type="text" formControlName="abbr" class="infofield" placeholder="000">
                                <!-- <div class="erroMessage"
                                    *ngIf="form.get('code')?.errors?.['required'] && form.get('code')?.touched">
                                    Address is required.
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="row">
                            <div class="col-sm-12">
                                <label class="l1">Business <span class="man-field"></span></label>
                            </div>
                            <div class="col-sm-12">
                                <select formControlName="business" class="infofield" required>
                                    <option value="">Select Business</option>
                                    <option *ngFor="let obj of business " [value]="obj.id">{{obj.name}}</option>
                                </select>
                                <div class="erroMessage"
                                    *ngIf="form.get('business')?.errors?.['required'] && form.get('business')?.touched">
                                    Business is required.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="text-right">
                <!-- <button mat-button (click)="approveAll()" class="universalbtn" style="background-color: green;">Approve all
                    fields</button> -->

                <button mat-button type="button" class="cancelbtn" (click)="cancel()">Cancel</button>
                <button mat-button type="submit" class="universalbtn" (click)="onSubmit()">Submit</button>
            </div>
        </div>
    </div>
</div>