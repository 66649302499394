import { Component, OnInit,Output } from '@angular/core';
import { AllLinksComponent } from 'src/app/core/all-links/all-links.component';
import { LearnerService } from 'src/app/core/services/learner.service';
import { EventEmitter } from '@angular/core';
@Component({
  selector: 'app-header-global',
  templateUrl: './header-global.component.html',
  styleUrls: ['./header-global.component.css']
})

export class HeaderGlobalComponent implements OnInit {
  AllLinksComponent: any;
  name: string;
  dates:any;
  fullName: string = "";
  jobTitle:string=" ";
  initials: string = "";
  showText: boolean;
  employeepermissions:any;
  showClaim:boolean;
  showHrmanager:boolean;
  showEseparation:boolean;
  poaPermission:any;
  showPoa:boolean;
  amdocPermission:any;
  showAmdoc:boolean;
  showNDC:boolean;
 
   constructor(
    private learnerService: LearnerService,
    public allLink : AllLinksComponent
   
  ) { }
  @Output()
  notify:EventEmitter<any>=new EventEmitter<any>();
  @Output()
  notifyhrmanager:EventEmitter<any> =new EventEmitter<any>();
  
  @Output()
  notifyshowEseparation:EventEmitter<any> =new EventEmitter<any>();
  
  @Output()
  notifyshowNDC:EventEmitter<any> =new EventEmitter<any>();
  
  @Output()
  notifyPOA:EventEmitter<any>=new EventEmitter<any>();

  @Output()
  notifyAmdoc:EventEmitter<any>=new EventEmitter<any>();
   
  ngOnInit(): void {
        this.name = localStorage.getItem('name');

    this.learnerService.getEmployeeDetails().subscribe(res => {
           this.employeepermissions=res['employee_permissions'];

           //console.log(res['employee_permissions'])

        //trying to react at employee permission and after that set in showClaim variable
        this.showClaim= this.employeepermissions.expense_manager;
        this.showHrmanager=this.employeepermissions.hr_manager;
        this.showEseparation=this.employeepermissions.e_seperation;
        this.showNDC=this.employeepermissions.ndc;
        localStorage.setItem('self', res['employee_permissions']['self']);
        localStorage.setItem('manager', res['employee_permissions']['manager']);
        localStorage.setItem('department_head', res['employee_permissions']['department_head']);
        localStorage.setItem('business_head', res['employee_permissions']['business_head']);
        localStorage.setItem('company', res['employee_permissions']['company']);
        localStorage.setItem('tab', 'self');
        //sending data to parent
        this.notify.emit(this.showClaim);
        this.notifyhrmanager.emit(this.showHrmanager);
        this.notifyshowEseparation.emit(this.showEseparation);
        this.notifyshowNDC.emit(this.showNDC);
        console.log('expense_manager value',this.showClaim);
        console.log('hr_manager value',this.showHrmanager);
        this.poaPermission=this.employeepermissions.poa_permission
        this.amdocPermission=this.employeepermissions.amdoc_permission
        if(this.poaPermission){
          if (this.poaPermission.includes('ceo') || this.poaPermission.includes('view_all') || this.poaPermission.includes('poa') ||this.poaPermission.includes('legal') || this.poaPermission === 'hod'){
            this.showPoa=true;
  
          }

        }  
        this.notifyPOA.emit(this.showPoa);
        //console.log('expense_manager value',this.showClaim);
        if(this.amdocPermission){
          this.showAmdoc=true
          console.log('yes the permission for amdoc is given',this.amdocPermission)
        }
        this.notifyAmdoc.emit(this.showAmdoc);

      this.dates = res['data'];      
      this.fullName = this.dates.name;
      this.jobTitle=this.dates.job_title; //new added
      const names = this.fullName.split(" ");
      // this.initials = names.map(name => name.charAt(0)).slice(0, 2).join("");
     if (this.dates.image == "" || this.dates.image == null) {
        this.initials = names[0].charAt(0) + names[names.length - 1].charAt(0);   
          this.showText=true;  
      }
    else {
      this.showText=false;
        var elem = document.querySelector('#roundImg')
        var DOM_img = document.createElement("img");
        DOM_img.src = `data:image/png;base64,${this.dates.image}`;
        elem.appendChild(DOM_img);
        DOM_img.style.width = '100%';

    }
  },
      (error: any) => {
          console.log(error, error.error, error.message);

      });

     
  }
 
  
  }


